import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";

import {
  OverflowText,
  Rating,
  TextTooltip,
} from "@transfr-inc/dashboard-components";
import {
  BasicCell,
  Column,
  ColumnOptions,
  ColumnType,
  DataTable,
  SortDirection,
  TimeDataCell,
} from "@transfr-inc/dashboard-components/data-table";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import { ColumnHeaderInformationTooltip } from "../../../../components/custom/content/column-header-information-tooltip";

import "./my-work.ce.simulations-table.scss";

export function MyWorkCeSimulationsTable({ sims, responsiveStyles }) {
  const columns = [
    new Column(
      "name",
      "Sim Name",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: false,
        customFunction: (value, { description }) => {
          return (
            <>
              <OverflowText text={value}>{value}</OverflowText>
              {description && (
                <TextTooltip text={description}>
                  <FontAwesomeIcon icon={"fa-regular fa-circle-info"} />
                </TextTooltip>
              )}
            </>
          );
        },
      })
    ),
    new Column(
      "clusterName",
      "Cluster",
      ColumnType.BASIC,
      new ColumnOptions({
        sortingEnabled: true,
        overflowEnabled: true,
        nullsLast: true,
      })
    ),
    new Column(
      "occupationName",
      "Career",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true, overflowEnabled: true })
    ),
    new Column(
      "timestamp",
      (
        <ColumnHeaderInformationTooltip
          headerTitle={"Date"}
          tooltipText={"Last attempted date"}
        ></ColumnHeaderInformationTooltip>
      ),
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => {
          const formattedDate = value ? formatDateDigits(value) : undefined;
          return <BasicCell value={formattedDate}></BasicCell>;
        },
      })
    ),
    new Column(
      "timeSpent",
      "Total Time",
      ColumnType.CUSTOM,
      new ColumnOptions({ sortingEnabled: true, customFunction: TimeDataCell })
    ),
    new Column(
      "rating",
      (
        <ColumnHeaderInformationTooltip
          headerTitle={"Rating"}
          tooltipText={"Last attempted rating (1-5)"}
        ></ColumnHeaderInformationTooltip>
      ),
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => <Rating value={value ?? 0} />,
      })
    ),
    new Column(
      "completed",
      (
        <ColumnHeaderInformationTooltip
          headerTitle={"Complete"}
          tooltipText={
            "Careers where trainees have reached the end. May or may not include a rating."
          }
          position={"top-end"}
        ></ColumnHeaderInformationTooltip>
      ),
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row) => {
          const icon =
            value || row.rating > 0
              ? ["fa-solid", "fa-circle-check"]
              : ["fa-regular", "fa-circle"];
          return (
            <div className="completed-cell">
              <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
            </div>
          );
        },
      })
    ),
    new Column(
      "code",
      "Code",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true, overflowEnabled: true })
    ),
  ];

  return (
    <DataTable
      className={clsx("my-work-ce-simulations-table", responsiveStyles)}
      columns={columns}
      defaultSortDirection={SortDirection.ASCENDING}
      defaultSortPropName={"clusterName"}
      data={sims}
      keyFields={["learningExperienceId"]}
      responsiveStyles={responsiveStyles}
    ></DataTable>
  );
}
