/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable no-undef */

export const parseCookies = () => {
  return window.document.cookie.split(";").reduce(function (acc, kvp) {
    const kv = kvp.split("=");
    const key = kv[0].trim();
    acc[key] = kv[1];
    return acc;
  }, {});
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const parseQuery = () => {
  const qs = window.location.search.slice(1);
  return qs.split("&").reduce((acc, kvp) => {
    const kv = kvp.split("=");
    if (kv[0]) {
      acc[kv[0]] = kv[1];
    }
    return acc;
  }, {});
};

export const uuid = () => {
  const url = URL.createObjectURL(new Blob());
  return url.slice(Math.max(0, url.lastIndexOf("/") + 1));
};

export const debounce = (callback, wait) => {
  let timeoutId;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

export const formatDate = (date = new Date()) => {
  date = date instanceof Date ? date : new Date(date);
  return date.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
    day: "numeric",
  });
};

export const getErrorMessage = (data) => {
  let errorMessage;
  if (data === undefined) {
    errorMessage = "Something went wrong. Please try again.";
  } else {
    errorMessage =
      data.detail == undefined
        ? "Something went wrong. Please try again."
        : data.detail;
  }
  return errorMessage;
};

export const noop = () => {};

export const checkPromisesFulfilled = (promises, errorMessage) => {
  if (promises?.some((p) => p.status === "rejected")) {
    throw new Error(errorMessage ?? defaultErrorMessage);
  }
  return promises;
};

export const getPromisesValues = (promises) => {
  return promises.map((p) => p.value);
};

export const stringToBoolean = (value) => value?.toLowerCase() === "true";
