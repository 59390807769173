import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import container from "../../../container";
import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { saveAs } from "file-saver";

import "./download-results.scss";
const ERROR_CSV_FILE = "errors.csv";
const CHANGES_CSV_FILE = "changes.csv";

export default () => {
  const { bulkUploadId } = useStoreState((store) => store.account);
  const actions = useStoreActions((store) => store.account);
  const { userService } = container;
  const [loading, setLoading] = useState();
  const [orgName, setOrgName] = useState();
  const [hasErrors, setHasErrors] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [newTraineesTotal, setNewTraineesTotal] = useState();
  const [updatedTraineesTotal, setUpdatedTraineesTotal] = useState();
  const [notification, setNotification] = useState();
  const [csvStrings, setCsvStrings] = useState();

  const downloadResultsCsv = async (downloadType) => {
    setLoading(true);

    const csvString =
      downloadType == "errors"
        ? csvStrings.errorsCsvString
        : csvStrings.successCsvString;

    const fileName =
      downloadType == "errors" ? ERROR_CSV_FILE : CHANGES_CSV_FILE;

    try {
      const blob = new Blob([csvString], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, fileName);
      saveAs();
      setNotification({
        type: NotificationType.success,
        message: `Successfully downloaded ${fileName}`,
        icon: ["fa-solid", "circle-check"],
      });
    } catch {
      setNotification({
        type: NotificationType.error,
        message: "Something went wrong",
        icon: ["fa-light", "fa-xmark"],
      });
    }
    setLoading();
    actions.setBulkUploadId();
  };

  const onDownload = (downloadType) => {
    downloadResultsCsv(downloadType);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await userService.getRosterResults(bulkUploadId);
        setCsvStrings(await userService.getRosterResultsCsv(data));
        setOrgName(data.organization_name);
        setNewTraineesTotal(data.result_dict.create_total);
        setUpdatedTraineesTotal(data.result_dict.update_total);
        if (
          data.result_dict.create_total > 0 ||
          data.result_dict.update_total > 0
        )
          setHasSuccess(true);
        if (data.result_dict.errors.length > 0) setHasErrors(true);
      } catch (error) {
        let errorMessage = "";
        errorMessage =
          error.status === 401
            ? "Incorrect account, please logout & try again"
            : "Something went wrong";
        setNotification({
          type: NotificationType.error,
          message: errorMessage,
          icon: ["fa-light", "fa-xmark"],
        });
      }
    };

    if (bulkUploadId) {
      getData();
    }
  }, []);

  return (
    <div className="update-notification-container">
      {!notification && !loading && orgName && hasErrors && (
        <Notification
          type={NotificationType.error}
          closable
          onClose={() => setOrgName()}
        >
          <div className="initial-error-message">
            We encountered problems with your roster request for{" "}
            <b>{orgName}</b>. Please download this
            <u onClick={onDownload("errors")} className="errors-link">
              <b>errors.csv</b>
            </u>
            for more information.
          </div>
        </Notification>
      )}
      {!notification && !loading && orgName && hasSuccess && (
        <Notification
          type={NotificationType.success}
          closable
          onClose={() => setOrgName()}
        >
          <div className="initial-error-message">
            We have successfully finished processing your roster request.
            {newTraineesTotal} new trainees have been created and{" "}
            {updatedTraineesTotal} trainees have been updated for your
            organization <b>{orgName}</b>. Please download
            <u onClick={onDownload("successes")} className="errors-link">
              <b>changes.csv</b>.
            </u>
          </div>
        </Notification>
      )}
      {loading && (
        <Notification
          type={NotificationType.info}
          icon={["fa-solid", "spinner"]}
          iconConfig={{ spin: true }}
        >
          <div className="update-message">Downloading errors</div>
        </Notification>
      )}
      {notification && (
        <Notification
          type={notification.type}
          icon={notification.icon}
          closable
          onClose={() => setNotification()}
        >
          {notification.message}
        </Notification>
      )}
    </div>
  );
};
